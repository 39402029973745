.page-loading-animation-container {
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    z-index: 100;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 400px;
    transition: all 0.3s ease-in-out;
    pointer-events: all;
    overflow: hidden;
    gap: 10px;
}

.page-loading-animation-container .logo {
    animation: blink 2s linear infinite;
}


.page-loading-animation-container .logo img {
    max-width: 300px;
}

@keyframes blink {
    0% {
        opacity: .5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .5;
    }
}

.page-loading-animation-container .logo img {
    object-fit: contain;
}

.page-loading-animation-container .img-responsive {
    width: 30pt;
    height: 30pt;
}
