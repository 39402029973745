.mui-appbar {
    background-color: #37b24a;
    color: #FFF;
    margin: -8px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
}

.mui--appbar-height {
    height: 10vh;
}

header ul.mui-list--inline {
    margin-bottom: 0;
}

header a {
    color: #fff;
}

header table {
    width: 100%;
}
.mui--text-title {
    padding-left: 15px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

/**
* Content CSS
*/
#content-wrapper {
    min-height: 100%;

    /* sticky footer */
    box-sizing: border-box;
    margin: -8px -8px -100px;
    padding-bottom: 100px;
}

li {
    list-style-type: none;
}

ul {
    padding: 0;
}

.mui-imagem{
    width: 50%;
    float: left;
    background-image: url("./education.jpg");
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.mui-content {
    float: right;

    width: 46%;
    padding-top: 60px;
}

@media (max-width: 960px) {
    .mui-content {
        float: right;
        width: 100%;
    }

    .mui-imagem{
        display: none;
    }
}