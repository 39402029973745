/****** ALERT ******/

.alert-success {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    background-color: #2E9963;
    border-color: #2E9963;
    padding: 20px 24px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.alert-bottom {
    position: fixed;
    bottom: 5px;
    width: 100%;
    z-index: 99;
}

.alert-info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #22734B;
    background-color: #EBFFF5;
    border-color: #CEF2E0;
    border-radius: 6px;
    padding: 12px 20px;
    margin-top: 16px;
}

.alert-warning {
    color: #CC5500;
    background-color: #FFF8EB;
    border-color: #FEEBCB;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
    padding: 12px 20px;
}

.msg-white {
    width: 90%;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
    background: #0068B3;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 8px 18px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    border: 0;
    color: #FFFFFF;
    padding: 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
}

.msg-white .close {
    float: right;
    text-shadow: none;
    opacity: 1;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.2px;
    color: #062840;
    line-height: 32px;
}

.obrigatorio {
    margin-top: .25rem;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #DC393A;
}

