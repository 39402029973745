/****** BUTTONS ******/

.medium {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.medium object {
    vertical-align: middle;
}


/*** BTN PRIMARY ***/

.btn-primary {
    font-style: normal;
    font-family: "Inter", Arial !important;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    text-decoration: none;
    background-color: var(--primary-color);
}

.btn-primary:hover {
    color: #FFFFFF;
    background-color: var(--primary-color-onBack);
    text-decoration: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: var(--primary-color-drop);
    outline: none;
}

.btn-primary.focus,
.btn-primary:focus {
    color: #FFFFFF;
    background-color: var(--primary-color-drop);
    box-shadow: none;
    outline: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #FFFFFF;
    background-color: var(--primary-color-disable);
    cursor: not-allowed;
    outline: none;
}


/*** BTN SECONDARY***/

svg path {
    transition: 0.5s;
}

.btn-secondary {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #424E66!important;
    background-color: #FFFFFF!important;
    border: 1px solid #B8C6D9;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    transition: 0.5s;
}

.btn-secondary:hover {
    color: #738399;
    border: 1px solid #B8C6D9;
    box-sizing: border-box;
    background-color: #E9E9E9!important;
    text-decoration: none;
}

.btn-secondary:hover svg path {
    stroke: #788399 !important
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    border: 1px solid #738399;
    box-sizing: border-box;
    color: #424E66;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
    outline: none;
}

.btn-secondary.focus,
.btn-secondary:focus {
    border: 1px solid #738399;
    box-sizing: border-box;
    color: #424E66;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
    outline: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #DDE3ED;
    border: 1px solid #DDE3ED;
    background-color: #FFFFFF;
    box-sizing: border-box;
    box-shadow: none;
    cursor: not-allowed;
    outline: none;
}


/*** BTN LINK ***/

.btn-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-color);
    text-decoration: none;
    letter-spacing: -0.4px;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: transparent;
}

.btn-link span {
    border-bottom: 1px solid var(--primary-color);
}

.btn-link span:hover {
    color: var(--primary-color-onBack);
    border-bottom: 1px solid var(--primary-color-onBack);
}

.btn-link:hover {
    text-decoration: none;
}

.btn-link span:not(:disabled):not(.disabled).active,
.btn-link span:not(:disabled):not(.disabled):active,
.show>.btn-link.dropdown-toggle {
    outline: none;
    color: var(--primary-color-drop);
    border-bottom: 1px solid var(--primary-color-drop);
}

.btn-link.focus,
.btn-link span:focus {
    outline: none;
    color: var(--primary-color-drop);
    border-bottom: 1px solid var(--primary-color-drop);
}

.btn-link.disabled,
.btn-link span:disabled {
    color: #DDE3ED;
    cursor: not-allowed;
    pointer-events: stroke;
    border-bottom: 1px solid #DDE3ED;
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
}