/****** PAGAMENTO ******/

.box-servicos {
    /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.08); */
    border-radius: 6px;
    width: 100%;
    margin-bottom: 24px;
}

.box-servicos p {
    background: #F5F7FA;
    padding: 16px 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin: 0;
}

.box-servicos .box-body {
    /* padding: 24px 24px 16px; */
}

.box-servicos .box-body h3 {
    font-family: "Inter", Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: var(--primary-color-disable);
}

.box-servicos .box-body h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #11151F;
}

.form-logado .mt {
    margin-top: 8px;
}

.form-pagamento h3 {
    padding: 16px 0 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
}

.input-group input {
    border: 1px solid #B8C6D9;
    border-radius: 6px;
    border-right: 0;
}

.input-group input {
    padding-right: 0;
}

.input-group .seb-credit {
    width: 45px;
    height: 28px;
}

.input-group .input-group-addon {
    border: 1px solid #B8C6D9;
    border-left: 0;
    border-radius: 0 6px 6px 0;
    margin: auto;
    line-height: 46px;
    padding-right: 16px;
}

.form-descricao h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
}

.form-descricao p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
}

.boleto {
    background: #F5F7FA;
    border-radius: 6px;
    padding: 14px 16px;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
}

.boleto span {
    color: #11151F;
}

.content-blue {
    background: #F0F9FF;
    border: 2px solid #D1ECFF;
    border-radius: 12px;
    margin-top: 32px;
}

.content-blue h2 {
    font-family: "Inter", Arial;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--primary-color-disable);
    padding: 24px 32px;
    border-bottom: 1px solid #D1ECFF;
}

.contrato {
    padding: 24px 32px;
}

.contrato a:link {
    font-family: "Inter", Arial;
    color: #0068B3 !important;
}

.contrato-html {
    height: 250px;
}

.sidebarbox__title .header h4 {
    display: none;
}

.sidebarbox-btn {
    display: none;
}

#responsavelMobile {
    display: none;
}

#cancel-btn-mobile {
    display: none;
}

.funkyradio {
    clear: both;
    /* overflow: hidden; */
    padding: 22px;
    position: relative;
}

.funkyradio div p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
    padding: 0 0 0 35px;
    margin: 0;
    width: 63%;
}

.funkyradio label {
    width: 100%;
    font-weight: normal;
    font-family: "Inter", Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.4px;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
    display: none;
    transition: .5s;
}

.funkyradio input[type="radio"]:empty~label,
.funkyradio input[type="checkbox"]:empty~label {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 35px;
    /* identical to box height, or 178% */
    /* Dark gray / 500 (Base) */
}

.funkyradio input[type="radio"]:empty~label:before,
.funkyradio input[type="checkbox"]:empty~label:before {
    position: absolute;
    display: block;
    top: 5px;
    bottom: 0;
    left: 0;
    content: '';
    border-radius: 50px;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #B8C6D9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.funkyradio input[type="radio"]:hover:not(:checked)~label,
.funkyradio input[type="checkbox"]:hover:not(:checked)~label {
    color: #738399;
}

.funkyradio input[type="radio"]:hover:not(:checked)~label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked)~label:before {
    content: '\2714';
    text-indent: 7px;
    line-height: 24px;
    font-size: 12px;
    color: #738399;
}

.funkyradio input[type="radio"]:checked~label,
.funkyradio input[type="checkbox"]:checked~label {
    color: #11151F;
    font-family: "Inter", Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.4px;
}

.funkyradio input[type="radio"]:checked~label:before,
.funkyradio input[type="checkbox"]:checked~label:before {
    content: '\2714';
    text-indent: 7px;
    color: #333;
    background-color: #ccc;
    font-size: 12px;
    line-height: 24px;
}

.funkyradio input[type="radio"]:focus~label:before,
.funkyradio input[type="checkbox"]:focus~label:before {
    box-shadow: 0 0 0 3px #999;
}

.funkyradio-success input[type="radio"]:checked~label:before,
.funkyradio-success input[type="checkbox"]:checked~label:before {
    color: #fff;
    background-color: #2E9963;
}

.funkyradio.entrada-radio {}

.funkyradio a:link {
    color: #0068B3 !important;
}

.ribbon-container {
    position: absolute;
    overflow: visible;
    font-size: 16px;
    line-height: 18px;
    margin: 0 auto;
    top: 50%;
    right: -6px;
    transform: translate(0, -50%);
}

.ribbon-container .ribbon {
    display: table-cell;
    text-align: center;
    padding: 7px;
    position: relative;
    background: #2E9963;
    overflow: visible;
    height: 58px;
    margin-left: 29px;
    color: #fff;
    text-decoration: none;
    vertical-align: middle;
    width: 160px;
}

.ribbon-container .ribbon:after {
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    bottom: -5px;
    right: 0;
    border-top: 5px solid #22734B;
    border-right: 5px solid transparent;
}

.ribbon-container .ribbon:before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    left: -29px;
    border-top: 29px solid #2E9963;
    border-bottom: 29px solid #2E9963;
    border-right: 2px solid transparent;
    border-left: 29px solid transparent;
}

.ribbon-container:before {
    content: "";
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: -4px;
    right: 0;
    border-bottom: 5px solid #22734B;
    border-right: 5px solid transparent;
}

.ribbon-container .ribbon span {
    font-size: 12px;
}

.ribbon-container.normal .ribbon {
    background: #CEF2E0;
    color: #22734B;
    width: 160px;
}

.ribbon-container.normal .ribbon:before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    left: -29px;
    border-top: 29px solid #CEF2E0;
    border-bottom: 29px solid #CEF2E0;
    border-right: 2px solid transparent;
    border-left: 29px solid transparent;
}

.panel-group {
    margin-top: 16px;
}

.panel-group .panel:first-child {
    border-radius: 4px 4px 0 0;
    border-top: 1px solid #DDE3ED
}

.panel-group .panel:last-child {
    border-radius: 0 0 4px 4px;
    border-top: 0;
}

.panel-group .panel:first-child:last-child {
    border-radius: 4px;
    border-top: 1px solid #DDE3ED
}

.panel-group .panel {
    border: 1px solid #DDE3ED;
    border-top: 0;
}

.panel .panel-heading {
    background: rgba(245, 247, 250, 0.8);
}

.panel .panel-heading h4 label {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #11151F;
    width: 100%;
    padding: 20px 32px;
}

.panel .panel-heading h4 {
    margin-bottom: 0;
}

.panel-body {
    padding: 32px;
}
