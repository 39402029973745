/****** CHECK SWITCH  ******/

.switch input[type=checkbox] {
    display: none;
}

.switch input[type=checkbox]+label {
    position: relative;
    min-width: calc(calc(2.375rem * .8) * 2);
    border-radius: calc(2.375rem * .8);
    height: calc(2.375rem * .8);
    line-height: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
}

.switch input[type=checkbox]+label::before,
.switch input[type=checkbox]+label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(calc(2.375rem * .8) * 2);
    bottom: 0;
    display: block;
}

.switch input[type=checkbox]+label::before {
    right: 0;
    background-color: #B8C6D9;
    border-radius: calc(2.375rem * .8);
    transition: .2s all;
}

.switch input[type=checkbox]+label::after {
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s ease-in 0s;
}

.switch input[type=checkbox]:checked + label::before {
    background-color: var(--primary-color-disable);
}

.switch input[type=checkbox]:checked+label::after {
    margin-left: calc(2.375rem * .8);
}

.switch input[type=checkbox]:focus+label::before {
    outline: none;
    box-shadow: 0 0 0 .2rem rgba(0, 136, 221, .25);
}

.switch input[type=checkbox]:disabled+label {
    color: #868e96;
    cursor: not-allowed;
}

.switch input[type=checkbox]:disabled+label::before {
    background-color: #e9ecef;
}

.switch.switch-xs {
    font-size: .8rem;
}

.switch.switch-xs input[type=checkbox]+label {
    min-width: calc(calc(1.5375rem * .8) * 2);
    height: calc(1.5375rem * .8);
    line-height: calc(1.5375rem * .8);
    text-indent: calc(calc(calc(1.5375rem * .8) * 2) + .5rem);
}

.switch.switch-xs input[type=checkbox]+label::before {
    width: calc(calc(1.5375rem * .8) * 2);
}

.switch.switch-xs input[type=checkbox]+label::after {
    width: calc(calc(1.5375rem * .8) - calc(2px * 2));
    height: calc(calc(1.5375rem * .8) - calc(2px * 2));
}

.switch.switch-xs input[type=checkbox]:checked+label::after {
    margin-left: calc(1.5375rem * .8);
}

.switch.switch-sm {
    font-size: .875rem;
}

.switch.switch-sm a {
    display: block;
}

.switch.switch-sm object {
    vertical-align: middle;
    margin-left: 5px;
}

.switch.switch-sm input[type=checkbox]+label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: 24px;
    line-height: calc(1.9375rem * .8);
    text-indent: 48px;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.2px;
    color: #424E66;
    display: inline-table;
}

.switch.switch-sm input[type=checkbox]+label::before {
    width: 40px;
    height: 24px;
}

.switch.switch-sm input[type=checkbox]+label::after {
    width: 16px;
    height: 16px;
}

.switch.switch-sm input[type=checkbox]:checked+label::after {
    margin-left: calc(1.93rem * .5);
}

.switch+.switch {
    margin-left: 1rem;
}

.studying .switch.switch-sm span {
    display: inline-block;
    text-indent: 0;
    padding-right: 0;
    margin-right: 0;
}

.switch.switch-sm span {
    display: inline-block;
    text-indent: 0;
    padding-right: 32px;
    margin-right: 16px;
}