
/****** TOAST ******/
/* class custom TOAST*/
.Toastify__toast-container--top-right {
    top: 5em !important;
    width: 400px !important;
}

.toastMsg h5 {
    margin-left: 25px;
}

/*Custumização toast Sucesso*/
.toastMsgSuccess {
    color: #07bc0c;
    background-image: url(../components/assets/icones/circle-check.svg);
    background-repeat: no-repeat;
    background-size: calc(1.3em + .375rem) calc(1.4em + .375rem);
    background-position: left calc(.375em + .1875rem) top;
}

/*Custumização toast warning*/
.toastMsgWarning {
    color: #f1c40f;
    background-image: url(../components/assets/icones/alert-warning.svg);
    background-repeat: no-repeat;
    background-size: calc(1.3em + .375rem) calc(1.4em + .375rem);
    background-position: left calc(.375em + .1875rem) top;
}

/*Custumização toast error*/
.toastMsgError {
    color: #e74c3c;
    background-image: url(../components/assets/icones/danger.svg);
    background-repeat: no-repeat;
    background-size: calc(1.3em + .375rem) calc(1.4em + .375rem);
    background-position: left calc(.375em + .1875rem) top;
}

/*Custumização toast info*/
.toastMsgInfo {
    color: #3498db;
    background-image: url(../components/assets/icones/info.svg);
    background-repeat: no-repeat;
    background-size: calc(1.0em + .375rem) calc(1.4em + .375rem);
    background-position: left calc(.375em + .1875rem) top;
}



.toast-main {
    top: 0;
    right: 0;
}

.toast {
    width: 475px;
    max-width: 459px;
    overflow: hidden;
    background-color: #FFFFFF;
    opacity: 0;
    border: 1px solid #B8C6D9;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    position: relative;
    top: 32px;
    right: 32px;
    z-index: 0;
}

.toast.show {
    display: block;
    opacity: 1;
    z-index: 2;
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 24px 0;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #CC2D2E;
}

.toast-header.success {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 24px 0;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #2E9963;
}

.toast-header.exclamtion {
    color: #CC5500;
}

.toast-body {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    padding: 0 65px 24px 60px;
}

.ml-2.mb-1.close {
    font-size: 25px;
    color: #738399;
    font-weight: 300;
}