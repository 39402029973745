.card-container {
    height: 100%;
    display: flex;
    align-items: center;
}
.card-validation {
    max-width: 600px;
    height: fit-content;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-validation h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.card-validation h3 {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
}

.card-validation p {
    margin: 5px 0;
    color: #777;
}

.card-validation .valid {
    color: #4caf50;
    font-weight: bold;
}

.card-validation .invalid {
    color: #f44336;
    font-weight: bold;
}

.card-validation .title-value {
    display: flex;
    font-weight: bold;
    padding: 5px;
}

.card-validation .card-text {
    font-weight: normal;
}

.card-validation .back-button {
    margin-top: 20px;
}