/****** SELECT ******/

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2em + 1rem + 0px);
    padding: .375rem .75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
    vertical-align: middle;
    background: transparent;
    border: 1px solid #B8C6D9;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.selecionar,
.hoverOpcao {
    cursor: pointer;
}

.btn-light {
    color: #738399;
    background-color: #FFFFFF;
    border-color: #B8C6D9;
    padding: 12px 16px;
    border-radius: 6px;
}

.btn-light:hover {
    color: #738399;
    background-color: #FFFFFF;
    border-color: #B8C6D9;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #738399;
    background-color: #FFFFFF;
    border-color: #B8C6D9;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
    border: 2px solid #0068B3;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: 0 !important;
    outline: 0 !important;
    outline-offset: 0;
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #0068B3;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
}

.dropdown-item.active,
.dropdown-item:active {
    color: #FFFFFF !important;
    text-decoration: none;
    background: var(--primary-color-disable);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    padding-left: 8px !important;
}

.dropdown-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    padding: 8px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 4px;
}

.bootstrap-select .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #DDE3ED;
}

.bootstrap-select .dropdown-menu li.dropdown-divider {
    border-bottom: 0;
    border: 0;
    margin: 0;
}

.bootstrap-select .dropdown-menu li:last-child {
    border-bottom: 1px solid transparent;
}

.bootstrap-select .dropdown-menu.inner {
    padding: 8px;
}

.bootstrap-select .dropdown-toggle .filter-option {
    width: 90%;
}

.bootstrap-select>.dropdown-toggle::after {
    position: absolute;
    right: 17px;
    top: 17px;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
    border: solid #424E66;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.bootstrap-select>.dropdown-toggle::before {
    position: absolute;
    right: 17px;
    top: 25px;
    display: inline-block;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
    border: solid #424E66;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.dropup .dropdown-toggle::after {
    position: absolute;
    right: 17px;
    top: 17px;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
    border: solid #424E66;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #B8C6D9;
    background-color: #FFFFFF;
    border-color: #DDE3ED;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
}

.disabled .dropdown-toggle::after {
    border: solid #B8C6D9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.disabled .dropdown-toggle::before {
    border: solid #B8C6D9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.bootstrap-select .dropdown-menu li a span.text {
    display: inline-block;
    width: 100%;
}

.bootstrap-select .dropdown-menu li small {
    padding-left: .5em;
    float: right;
    color: #424E66 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.2px;
}

.dropdown-header {
    display: block;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #0068B3;
    padding: 16px 0;
}

.bootstrap-select .dropdown-menu li a.opt {
    position: relative;
    padding-left: 8px;
}
