.smo-search-input {
    position: relative;
}

.smo-search-input input {
    padding: 0 10px;
}

.smo-search-input svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 28px;
    height: 28px;
    fill: #738399;
}

.smo-search-input svg:hover {
    cursor: pointer;
    fill: var(--primary-color);
}

.smo-search-input svg:active {
    fill: var(--primary-color);
    filter: brightness(0.8);
}