.progress-title {
    text-align: left;
    padding: 24px;
    border-bottom: 1px solid #DDE3ED;
    display: table;
    width: 100%;
}

.welcome-progress .progress-title {
    text-align: left;
    padding: 0;
    border-bottom: none;
    display: table;
    width: 100%;
    margin-bottom: 8px;
}

.progress-title h1 {
    font-family: Inter, Arial, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
    float: left;
    margin: 5px 0;
}

.progress {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: none;
    box-shadow: none;
    position: relative;
    float: left;
    margin-right: 16px;
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #DDE3ED;
    position: absolute;
    top: 0;
    left: 0;
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    border-radius: 50%;
    background: #FFFFFF;
    color: #424E66;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
}

.progress.blue .progress-bar {
    border-color: #0068B3;
}

.status-progress {
    display: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #0068B3;
}