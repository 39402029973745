/****** TOOLTIP ******/

.tooltip>.tooltip-inner {
    font-family: "Inter", Arial;
    background-color: #262F40;
    padding: 16px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    max-width: 368px;
    width: 368px;
}

.bs-tooltip-top {
    top: -35px !important;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    margin-left: 2px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: #262F40;
}

.tooltip.show {
    opacity: 1;
}