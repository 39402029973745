/****** FORM VALIDATION ******/

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border: 2px solid #DC393A;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #DC393A;
    box-shadow: none;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #DC393A;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #FFFFFF;
    opacity: 1;
    border-color: #DDE3ED;
    cursor: not-allowed;
    color: #B8C6D9;
}

.custom-select:disabled {
    color: #B8C6D9;
}

.bootstrap-select.is-invalid .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle,
.was-validated .bootstrap-select select:invalid+.dropdown-toggle {
    border-color: #dc393a;
    border: 2px solid #dc393a;
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.bs-invalid:after {
    border: 0 !important;
}

.bs-invalid:before {
    border: 0 !important;
}

.bs-invalid~.invalid-feedback {
    display: block;
}
