/****** STYLE GERAL ******/

:root {
    --header-z-index: 100;
    --modal-z-index: 200;
    --sidebar-z-index: 300;
    --checkout-action-buttons-z-index: 1000;
    --droz-chat-z-index: 1048;
    --select-full-screen-z-index: 1049;
    --dialog-z-index: 1050;
    --primary-color: #0068b3;
    --primary-color-onBack: #0068b3;
    --primary-color-drop: #0077cd;
    --primary-color-disable: #004a80;
    --primary-color-background: #003c67;
    --primary-color-selecionado: #0086e6;
}


* {
    font-family: "Inter", Arial!important;
}

html,
body,
#root {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
}

html {
    direction: ltr;
    overflow-x: hidden;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

body {
    font-family: "Inter", Arial !important;
    background-color: #F5F7FA;
    position: relative;
    margin: 0;
}

body.body-logado {
    background-image: linear-gradient(180deg, red, yellow);
    background-repeat: no-repeat;
    background-position: top;
}

body a {
    outline: none !important;
}

h2 {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
}

hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0;
    border-top: 1px solid #DDE3ED;
}

hr.hr {
    margin: 24px 0;
}

.section {
    margin: 32px 0;
}

.section-container {
    margin-left: 10px;
    margin-right: 10px;
}

/*#form-escolar .col-lg-4 {*/
/*    z-index: 1;*/
/*}*/

.mt {
    margin-top: 32px;
}

.mb {
    margin-bottom: 32px;
}

.pxy {
    padding: 40px 40px;
}

.img-contrato {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.spinner-border {
    margin-right: 2px;
    width: 0.9rem;
    height: 0.9rem;
    float: left;
    font-size: 8px;
    top: 5px;
    position: relative;
}

.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

.obs-text {
    padding: 32px;
}