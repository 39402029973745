/******ICONE SEB ******/

.seb {
    vertical-align: middle;
}

.seb.seb-question {
    margin-right: 4px;
    width: 22px;
    height: 22px;
}

.seb.seb-bolt {
    width: 14px;
    height: 18px;
    margin-right: 11px;
    vertical-align: sub;
}

.seb.seb-arrow-right {
    width: 24px;
    height: 24px;
}

.seb.seb-calendar {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.seb.seb-pencil {
    width: 24px;
    height: 24px;
}

.seb.seb-upload {
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto 16px;
}

.seb.seb-billet {
    width: 45px;
    height: 28px;
    margin-right: 13px;
}