#accordionContracts {

}

#accordionContracts .card-header h2 {
    color: black;
}

#accordionContracts .card-header.expanded {
    background-color: #0068B3;
}

#accordionContracts .card-header.expanded h2,
#accordionContracts .card-header.expanded button {
    color: white;
}
