/****** TIMELINE ******/

.timeline {
    display: block;
    position: relative;
    padding: 65px 120px;
}

.timeline a {
    margin: 10px 30px;
}

.timeline.timeline-escolar {
    padding: 28px;
}

.timeline.timeline-escolar h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
}

.timeline ul.tl {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.timeline.timeline-escolar ul.tl {
    margin: 0 6px;
    padding: 0;
    display: inline-block;
}

.timeline ul.tl li {
    list-style: none;
    min-height: 69px;
    border-left: 2px dotted #86D6FF;
    padding: 0 30px 30px 30px;
    position: relative;
    counter-increment: item;
}

.timeline.timeline-escolar ul.tl li {
    list-style: none;
    min-height: 69px;
    border-left: 2px dotted #B8C6D9;
    padding: 0 30px 30px 27px;
    position: relative;
    counter-increment: item;
}

.timeline ul.tl li:last-child {
    border-left: 2px dotted transparent;
}

.timeline ul.tl li::before {
    content: counter(item);
    position: absolute;
    left: -15px;
    top: 0;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #0068B3;
    border: 2px solid #D1ECFF;
    border-radius: 500%;
    background: #F0F9FF;
    height: 28px;
    width: 28px;
}

.timeline.timeline-escolar ul.tl li::before {
    content: " ";
    position: absolute;
    left: -9px;
    top: 0;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #0068B3;
    border: 2px solid #DDE3ED;
    border-radius: 500%;
    background: #FFFFFF;
    height: 18px;
    width: 18px;
}

.timeline.timeline-escolar ul.tl li.check::before {
    background: #2E9963;
    left: -8px;
    border: 2px solid #2E9963;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center;
}

.timeline ul.tl li:hover::before {
    border-color: #0068B3;
    transition: all 1000ms ease-in-out;
}

ul.tl li .item-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #424E66;
    text-align: justify;
}

ul.tl li .item-detail b {
    font-family: Inter, Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #424E66;
}

.timeline.timeline-escolar ul.tl li .item-detail {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #11151F;
}

.timeline.timeline-escolar ul.tl li .item-detail span {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    display: block;
}

.timeline.modal-timeline {
    padding: 0;
}

.timeline.modal-timeline ul.tl li {
    border: 0;
    min-height: 0;
}

.item-detail span {
    color: #0068B2;
    font-weight: 700;
}