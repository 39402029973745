
/*add erro na validação de formulario*/

.validError {
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #DC393A !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nI0RDMzkzQScgc3Ryb2tlPScjRkZGRkZGJyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc2Jy8+PHBhdGggc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgZD0nTTUuOCAzLjZoLjRMNiA2LjV6Jy8+PGNpcmNsZSBjeD0nNicgY3k9JzguMicgcj0nLjUnIGZpbGw9JyNGRkZGRkYnIHN0cm9rZT0nbm9uZScvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-position: right calc(.375em + .1875rem) center;
}

input[type=date].validError {
    background-position: right calc(.375em + 2.5rem) center !important;
}

select.validError {
    background-position: right calc(.375em + 0.9rem) center !important;
}


/*add ok na validação de formulario*/

.validOk {
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #28a745 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-position: right calc(.375em + .1875rem) center;
    padding-right: 20px;
}

input[type=date].validOk {
    background-position: right calc(.375em + 2.5rem) center !important;
}

select.validOk {
    background-position: right calc(.375em + 0.9rem) center !important;
}


.validOkDocumento {
    border: 2px dashed #28a745 !important;
}

.validErrorDocumento {
    border: 2px dashed #DC393A !important;
}

/*.modal-dialog {*/
/*    max-width: 850px !important;*/
/*    margin: 1.75rem auto;*/
/*}*/


.modalLoadingImagesComplementar {
    position: fixed;
    z-index: 999;
}