.modal-error {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: #f1f0ff;
    text-align: center;
    z-index: 999;
}

.modal-error h2 {
    text-align: center;
    color: #0068b3;
    font-size: 25px;
    display: block;
    margin-bottom: 10px;
}

.modal-error h3 {
    text-align: center;
    color: #0068b3;
    font-size: 20px;
    display: block;
    margin-bottom: 40px
}

.section.manutencao {
    margin: 0;
}

.modal-error img {
    width: 600px;
}

a.link.disabled {
    color: #DDE3ED !important;
    text-decoration: none;
    cursor: not-allowed;
}