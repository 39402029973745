/****** ICONE HELP ******/

.icon-help a {
    position: fixed;
    bottom: 24px;
    right: 24px;
    background: #004A80;
    border-radius: 23px;
    padding: 13px 22px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
}