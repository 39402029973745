/****** MEDIA QUERY ******/

/*@media (min-width: 576px) {*/
/*    .modal-dialog.modal-md {*/
/*        max-width: 450px;*/
/*        margin: 1.75rem auto;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 1255px) {*/
/*    .section-container {*/
/*        margin-left: 40px;*/
/*        margin-right: 40px;*/
/*    }*/
/*}*/




@media only screen and (max-width: 991px) {
    /*.bg-dark {*/
    /*    background-color: #062840 !important;*/
    /*    height: auto;*/
    /*}*/
    .mx {
        margin-top: 24px;
    }
    #matricula .mx:first-child {
        margin-top: 0;
    }
    #cards-list .mx:first-child {
        margin-top: 0;
        padding: 0;
    }
    .sidebar {
        margin-top: 32px;
    }
    #cards-list {
        margin-top: 16px;
        margin-right: 16px;
        margin-left: 16px;
    }
    .cards-matricula {
        margin-right: 16px;
        margin-left: 16px;
    }
    .section .welcome {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-right: 16px;
        margin-left: 16px;
    }
    .icon-help {
        display: none;
    }
    /*#sidebar--mobile {*/
    /*    z-index: 9;*/
    /*}*/
    #sidebar--desktop {
        display: none;
    }
    .mt.text-right.btn-logado {
        margin: 24px 0 103px !important;
        padding: 0;
    }
    .header-content {
        padding: 24px;
        border-bottom: 1px solid #DDE3ED;
    }
    .header-content h2 {
        padding: 0 !important;
    }
    #editar-responsavel {
        display: none;
    }
    #btnEditarInformacoes {
        width: 100%;
    }
    /*.module .row:nth-child(odd) {*/
    /*    background: transparent;*/
    /*}*/
    /*.module .row.disabled .col-md-12:nth-child(odd) {*/
    /*    background: #F5F7FA;*/
    /*}*/
    /*.module .row:not(.disabled) .col-md-12:nth-child(even) {*/
    /*    background: #F5F7FA;*/
    /*}*/
    .module .row .col-md-6,
    .module .row .col-md-12 {
        padding: 16px 24px
    }
    .module .row {
        padding: 0;
    }
    #editar-responsavel {
        display: none;
    }
    #responsavelMobile {
        display: block;
    }
    .header-content {
        padding: 24px;
        border-bottom: 1px solid #DDE3ED;
    }
    /*.modal-header .close {*/
    /*    display: none;*/
    /*}*/
    /*.modal-open .modal {*/
    /*    overflow-x: hidden;*/
    /*    overflow-y: auto;*/
    /*    padding-right: 0 !important;*/
    /*    width: 100%;*/
    /*}*/
    /*.modal-footer button {*/
    /*    width: 100%;*/
    /*}*/
    /*.modal-body {*/
    /*    position: relative;*/
    /*    -ms-flex: 1 1 auto;*/
    /*    flex: 1 1 auto;*/
    /*    padding: 32px 24px;*/
    /*    height: auto;*/
    /*    overflow: auto;*/
    /*}*/
    /*.modal-dialog {*/
    /*    position: fixed;*/
    /*    width: 100%;*/
    /*    max-width: 100% !important;*/
    /*    bottom: 0;*/
    /*    pointer-events: none;*/
    /*    margin: 15px 0 0;*/
    /*}*/
    /*.modal-footer {*/
    /*    padding: 24px;*/
    /*}*/
    /*.modal-content {*/
    /*    max-height: 100%;*/
    /*    position: fixed;*/
    /*    bottom: 0;*/
    /*}*/
    /*.modal-dialog {*/
    /*    height: 100%;*/
    /*    margin-top: 0;*/
    /*}*/
    /*.modal-body {*/
    /*    overflow: auto;*/
    /*}*/
    /*.hidden-scroll {*/
    /*    overflow: hidden;*/
    /*}*/
    /*.sidebarbox__body {*/
    /*    overflow-y: auto;*/
    /*    height: 371px;*/
    /*}*/
    /*#cancel-btn-mobile {*/
    /*    display: block;*/
    /*}*/
    /*#cancel-btn-desktop {*/
    /*    display: none;*/
    /*}*/
    /*div#cadastro-concluido .modal-content {*/
    /*    position: fixed;*/
    /*    bottom: 0;*/
    /*}*/
    .section {
        margin: 0;
    }
    /*.btn-form {*/
    /*    width: 100%;*/
    /*    margin: 0;*/
    /*    padding: 24px;*/
    /*    text-align: center !important;*/
    /*}*/
    /*.section .welcome-progress {*/
    /*    margin: 24px 24px 16px;*/
    /*}*/
}

@media only screen and (max-width: 768px) {
    html,
    body {
        height: -webkit-fill-available;
    }
    .form-acesso {
        width: 100%
    }
    .general-form {
        width: 100%;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }
    .pxy {
        padding: 24px;
    }
    .timeline {
        padding: 24px;
    }
    .timeline ul.tl {
        margin-left: 12px;
    }
    .logo-wizard {
        padding: 16px;
    }
    .fluxo-img {
        padding: 0 16px;
    }
    .fluxo-timeline h1 {
        padding: 0px 0px;
        font-size: 24px;
        margin-bottom: 32px;
    }
    .status-progress {
        display: block;
        margin-bottom: 8px;
    }
    /*.progress-title h1 {*/
    /*    display: table-cell;*/
    /*    float: left;*/
    /*    margin: 0;*/
    /*    vertical-align: middle;*/
    /*}*/
    .switch.switch-sm span {
        display: inline-block;
        text-indent: 0;
        padding-right: 24px;
    }
    .btn-form {
        width: 100%;
        margin: 0;
        padding: 24px;
        margin: 0 !important;
        text-align: center !important;
    }
    .btn-form button:first-child {
        margin-left: 0px;
        margin-right: 6px;
    }
    .btn-logado button:first-child {
        margin-left: 0px;
        margin-right: 6px;
    }
    h2 {
        margin: 0;
    }
    .studying .text-right {
        text-align: left !important;
    }
    .toast {
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 95%;
        max-width: none;
    }
    .section-container {
        margin-left: 0;
        margin-right: 0;
    }
    .cards-matricula {
        margin-right: 16px;
        margin-left: 16px;
    }
    #matricula-realizada .cards-matricula {
        margin-right: 0;
        margin-left: 0;
    }
    .section .welcome {
        margin-right: 16px;
        margin-left: 16px;
    }
    .navbar {
        padding: 16px 16px;
    }
    .section .welcome-progress {
        margin: 24px 24px 16px;
    }
    .header-content span {
        display: none;
    }
    .section-login {
        margin-top: 16px;
        margin-right: 16px;
        margin-left: 16px;
    }
    .logo img {
        height: 60px !important;
        margin-bottom: 24px !important;
    }
    .logo-wizard img {
        text-align: left;
        width: 79.93px;
        height: 38px;
    }
    .timeline .medium.btn-primary {
        width: 100%;
        margin: 0;
    }
    .mt {
        margin-top: 24px;
    }
    .btn-logado a:first-child {
        margin-right: 28px;
    }
    .cards-matricula {
        margin-bottom: 12px;
    }
    .cards-matricula {
        margin-bottom: 24px;
    }
    #matricula .mx {
        margin-top: 0;
    }
    .nav-dados {
        /*display: none;*/
    }
    .form-logado {
        padding: 24px;
    }
    .content h2 {
        padding: 24px;
    }
    .text-right.btn-logado {
        text-align: center !important;
    }
    .mobile {
        display: block;
        float: left;
        margin-right: 15px;
    }
    .mobile form button {
        color: #FFFFFF !important;
        padding: 12px;
    }
    .mobile form button:hover,
    .mobile form button:focus {
        background: transparent !important;
        outline: none;
        box-shadow: none;
    }
    #responsavelMobile {
        display: block;
    }
    .ribbon-container {
        position: relative;
        overflow: visible;
        font-size: 16px;
        line-height: 18px;
        margin: 0 auto;
        top: 0;
        right: 0;
        transform: none;
        display: inline-table;
        width: 100%;
        margin-top: 20px;
    }
    .ribbon-container .ribbon {
        display: table-cell;
        text-align: center;
        padding: 7px;
        position: relative;
        background: #2E9963;
        overflow: visible;
        margin-left: 0;
        color: #fff;
        text-decoration: none;
        vertical-align: middle;
        width: 100%;
        border-radius: 4px;
        height: 61px;
    }
    .ribbon-container .ribbon:after {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: relative;
        bottom: 0;
        right: 0;
        border-top: 0;
        border-right: 0;
    }
    .ribbon-container .ribbon:before {
        content: "";
        height: 0;
        width: 0;
        position: relative;
        top: 0;
        left: 0;
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
    }
    .ribbon-container:before {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-bottom: transparent;
        border-right: transparent;
    }
    .ribbon-container.normal .ribbon {
        background: #CEF2E0;
        color: #22734B;
        width: 100%;
        display: table-cell;
    }
    .ribbon-container.normal .ribbon:before {
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
    }
    .modal-error img {
        width: 100%;
    }
}