/****** CARDS LIST *******/

#cards-list {
    margin-top: 72px;
}

.icone-avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.cards-list .siglas {
    width: 56px;
    height: 56px;
    background: linear-gradient(180deg, #B8C6D9 0%, #9FACBE 100%);
    border-radius: 50px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    line-height: 56px;
    margin-right: 16px;
    float: left;
}

.cards-list {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 24px 16px;
}

.cards-list h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
    margin-bottom: 0;
}

.descricao {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.cards-list p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 0;
}
