/****** AREA LOGADA ******/

.welcome h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
}

.welcome h2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
}

.section .welcome {
    margin-top: 79px;
    margin-bottom: 36px;
}

.section .welcome-progress {
    margin-top: 58px;
    margin-bottom: 48px;
}

.welcome-progress .progress:after {
    border: 5px solid #FFFFFF;
}

.welcome-progress .progress .progress-value {
    background: #EBEFF5;
}

.cards-matricula {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 44px 24px 24px;
    text-align: center;
    margin-bottom: 32px;
}

.cards-matricula .siglas {
    position: relative;
    width: 72px;
    height: 72px;
    background: linear-gradient(180deg, #B8C6D9 0%, #9FACBE 100%);
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    text-align: center;
    line-height: 75px;
    border-radius: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.cards-matricula .siglas .check-success {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    background: #2E9963;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
}

.cards-matricula .siglas .check-success .seb-check {
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
    margin: 0 auto;
}

.cards-matricula .siglas .check-danger {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    background: #FFA526;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
}

.cards-matricula .siglas .check-danger .seb-check {
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
    margin: 0 auto;
}

.cards-matricula h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #11151F;
    margin-bottom: 8px;
}

.cards-matricula p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 40px
}

.cards-matricula a {
    text-decoration: none;
}

.cards-matricula .medium {
    padding: 12px 8px;
}

.cards-matricula .flex-fill,
.cards-matricula .flex-sm-fill {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}

.msg-matriculado {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #22734B;
    background: #EBFFF5;
    border: 1px solid #CEF2E0;
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    vertical-align: sub;
}

.msg-matriculado.msg-danger {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #CC5500;
    background: #FFF8EB;
    border: 1px solid #FEEBCB;
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    vertical-align: sub;
}
