.smo-wallet-modal-container {

}

.smo-wallet-modal-body {
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.smo-wallet-modal-body.smo-wallet-modal-body-row {
    flex-direction: column;
    gap: 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.modal-dialog.smo-wallet-modal {
    max-width: 800px;
}

.smo-wallet-modal-add-card-button {
    width: 300px;
    height: 235px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.smo-wallet-modal-body.smo-wallet-modal-body-row .smo-wallet-modal-add-card-button {
    height: 70px;
    width: 100%;
    flex-direction: row;
    border-radius: 6px;
}

.smo-wallet-modal-add-card-button:hover {
    cursor: pointer;
    border-color: var(--primary-color);
    color: var(--primary-color);
    background: #f0f0f0;
}

.smo-wallet-modal-body.smo-wallet-modal-body-row .smo-wallet-card-selection {
    width: 100%;
}

.smo-wallet-card-selection {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    position: relative;
}

.smo-wallet-card-selection-container {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 14px;
    position: relative;
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.smo-wallet-card-selection-selected .smo-wallet-card-selection-container {
    border-color: var(--primary-color);
    background: white;
}

.smo-wallet-card-selection-container.smo-wallet-card-selection-container-row {
    flex-direction: row;
    width: 100%;
    border-radius: 6px;
    justify-content: space-between;
}

.smo-wallet-card-selection-container-row .smo-wallet-card-selection-button {
    margin-top: 0;
    margin-left: 8px;
}

.smo-wallet-card-selection-row-card-info-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    gap: 10px;
    max-width: 400px;
    border-right: 1px solid #ccc;
    padding-right: 8px;
}

.smo-wallet-card-selection-row-card-info-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    text-transform: capitalize;
}

.smo-wallet-card-selection-row-card-info-brand .payment-icon {
    height: 30px;
    width: auto;
    object-fit: contain;
}

.smo-wallet-card-selection-row-card-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
}

.smo-wallet-card-selection .smo-wallet-card-selection-verified-badge {
    position: absolute;
    left: 8px;
    top: -10px;
    z-index: 1;
}

.smo-wallet-card-selection-row-card-info p {
    margin: 0;
    background-color: #f1f3f44d;
    border-radius: 4px;
    padding: 2px;
}

.smo-wallet-card-selection-button {
    margin-top: 5px;
}

.smo-wallet-modal-brands-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.smo-wallet-modal-brands-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.smo-wallet-modal-brands-container p {
    margin: 0;
    padding: 0;
}

.smo-wallet-modal-brands-container div.smo-wallet-modal-brands {
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
}

.smo-wallet-modal-brands-container div.smo-wallet-modal-brands img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.smo-wallet-modal-header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.smo-wallet-modal-header .smo-search-input {
    width: 50%;
    min-width: 200px;
}

.smo-wallet-modal-header .smo-wallet-modal-header-btn {
    border: none;
    background: none;
    border-radius: 6px;
}

.smo-wallet-modal-header .smo-wallet-modal-header-btn.active {
    color: var(--primary-color);
}

.smo-wallet-modal-header .smo-wallet-modal-header-btn:hover {
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
}

.smo-wallet-modal-header .smo-wallet-modal-header-btn:active {
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
    filter: brightness(0.8);
}


.smo-wallet-card-selection .smo-wallet-card-selection-actions {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 5px;
}

.smo-wallet-card-selection .smo-wallet-card-selection-actions-confirmation-container {
    display: flex;
    justify-content: space-around;
    gap: 5px;
    flex-wrap: wrap;
    padding: 8px;
}

.smo-wallet-card-selection .smo-wallet-card-selection-actions-confirmation-container .smo-wallet-card-selection-actions-confirmation-container-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    width: 100%;
}

.smo-wallet-contract-container {
    width: 100%;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 8px 24px;
}

.smo-wallet-contract-list-container {
    list-style: none;
    margin: 0;
    padding: 0;
}

.smo-wallet-contract-container-dashed {
    border: 1px dashed rgb(128, 128, 128);
    padding: 15px;
}

.smo-wallet-contract-container-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.smo-wallet-contract-signature-container {
    width: calc(50% - 20px);
}

.smo-wallet-contract-signature-container p {
    margin-bottom: 0;
}

.smo-wallet-contract-signature-container hr  {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid black;
}

.smo-wallet-contract-container {
    position: relative;
    min-height: 350px;
}

.smo-wallet-contract-uppercase {
    text-transform: uppercase;
}

.smo-wallet-contract-checkbox-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.smo-wallet-contract-checkbox-container label,
.smo-wallet-contract-checkbox-container label span {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.1rem;
}

.smo-wallet-contract-checkbox-container label:hover {
    color: var(--primary-color);
}

.smo-wallet-contract-checkbox-container label input {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 600px) {
    .smo-wallet-modal .modal-body {
        padding: 4px;
    }
    .smo-wallet-modal .modal-body .col {
        padding: 0 5px;
    }
    .smo-wallet-card-selection-row-card-info {
        font-size: 12px;
        font-weight: bold;
    }

    .smo-wallet-card-selection-row-card-info p:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 110px;
    }

    .smo-wallet-card-selection-actions {
        flex-wrap: wrap;
    }

    .smo-wallet-card-selection-actions button {
        width: 100%;
    }
}

@media screen and (max-width: 1366px) and (min-width: 768px) {
    .badge.smo-wallet-card-selection-verified-badge {
        font-size: 0.8rem;
    }
}

