/*** DISABLED ***/

.disabled .siglas {
    width: 72px;
    height: 72px;
    background: #EBEFF5;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    text-align: center;
    line-height: 75px;
    border-radius: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.cards-matricula.disabled h3 {
    color: #B8C6D9;
}

.cards-matricula.disabled p {
    color: #B8C6D9;
}

.cards-matricula.disabled a {
    text-decoration: none;
}

.cards-matricula.disabled a span {
    border-bottom: 1px solid #0068B3;
}