/****** PAGE DADOS RESPONSAVEL ******/

.general-form {
    width: 708px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin: 0 auto;
}

.btn-form {
    width: 708px;
    margin: 32px auto;
}

.btn-logado a:first-child {
    margin-right: 24px;
    text-decoration: none;
}

.btn-logado a:visited {
    color: #424E66;
}

.btn-logado button:first-child {
    margin-right: 24px;
}

.btn-form button:first-child {
    margin-right: 24px;
}

.studying {
    border-bottom: 1px solid #DDE3ED;
    padding: 0 0 24px 0;
    margin-bottom: 40px;
}

.studying:last-of-type {
    border-bottom: none;
    padding: 0;
    margin-bottom: 0;
}

.studying hr:last-of-type {
    border-top: 0;
}
