/****** MOMENTO ESCOLA ******/

.content {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.content h2 {
    border-bottom: 1px solid #DDE3ED;
    padding: 32px;
    margin-bottom: 0;
    font-family: "Inter", Arial;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
}

.header-content {
    padding: 32px;
    border-bottom: 1px solid #DDE3ED;
}

.header-content h2 {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
}

.content h3 {
    font-family: "Inter", Arial;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
    margin-bottom: 0;
}

.content h3 object {
    margin-left: 6px;
}

.content p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 16px;
}

.content p span {
    color: #11151F;
}

.form-logado {
    padding: 32px;
}

.sidebar {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.sidebar .siglas {
    width: 48px;
    height: 48px;
    background: linear-gradient(180deg, #B8C6D9 0%, #9FACBE 100%);
    border-radius: 50px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    line-height: 48px;
    margin-right: 16px;
    float: left;
}

.header {
    display: flex;
    padding: 22px;
    border-bottom: 1px solid #DDE3ED;
}

.header .icone-avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.header h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--primary-color-disable);
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.conteudo {
    padding: 0 22px;
}

.historic {
    border-bottom: 1px solid #EBEFF5;
    padding: 23px 0;
}

.conteudo .historic:last-child {
    border-bottom: 0;
}

.historic h3 {
    font-family: "Inter", Arial;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
    margin: 0 0 8px;
}

.historic p {
    font-family: "Inter", Arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 0;
}

.sidebar-info {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #11151F;
    margin-bottom: 8px;
}

.sidebar-info.info {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #DC393A;
}

.sidebar-info.info span:last-child {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    text-align: left;
    float: none;
}

.sidebar-info.info span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.2px;
    color: #DC393A;
    float: right;
}

.sidebar-info.info a {
    text-decoration: none;
}

.sidebar-info span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
}

.sidebar-info span:first-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.2px;
    color: #11151F;
    float: right;
}

.sidebar-info.upselling span:last-child {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #11151F;
}

.sidebar-info.info svg {
    margin-right: 4px;
    margin-top: -1px;
}

.parcelas-entrada span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #11151F;
}

.parcelas-entrada span em {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    font-style: normal;
}

.parcelas-entrada span:last-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #11151F;
    text-align: right;
    letter-spacing: -0.2px;
    float: right;
}

.parcelas-entrada .parcela-valor {
    margin-top: 8px;
}

.cad-resposavel {
    padding: 32px;
}

.cad-resposavel .paragraph {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 24px;
}

.module .collapse,
.module .collapsing {
    height: 15rem;
}

.module .collapse {
    position: relative;
    display: block;
    overflow: hidden;
}

.module .collapse.show {
    height: auto;
}

.module .collapse.show:before {
    display: none;
}

.module a.collapsed:before {
    content: "";
    width: 14px;
    height: 10px;
    background-repeat: no-repeat;
    margin-right: 12px;
    display: inline-block;
}

.module a:not(.collapsed):before {
    content: "";
    width: 14px;
    height: 10px;
    background-repeat: no-repeat;
    margin-right: 12px;
    display: inline-block;
}

.module .row:nth-child(odd) {
    background: #F5F7FA;
}

.module .row {
    padding: 16px 32px
}

.module .collapse .row:last-child {
    border-radius: 0 0 30px 30px;
}

.module p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 0;
}

.module .col-md-6 p:last-child {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.2px;
    color: #11151F;
}

.btn-expand {
    border-top: 1px solid #DDE3ED;
    padding: 24px 32px;
}
