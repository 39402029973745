/****** FORM INPUTS ******/

.form-control {
    display: block;
    width: 100%;
    height: calc(2em + 1rem + 0px);
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #B8C6D9;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #0068B3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
}

.form-control::placeholder {
    color: #B8C6D9;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #B8C6D9;
}

label {
    display: inline-block;
    margin-bottom: 0;
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: left;
}

.disabled label {
    color: #DDE3ED;
}

.disabled .col-md-6 p {
    color: #738399;
}

.module .disabled .col-md-6 p:last-child {
    color: #738399;
}

.msg-input {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
}

.form-text.text-muted {
    float: left;
    font-family: "Inter", Arial;
    font-style: normal;
    font-weight: normal;
    color: #738399 !important;
    letter-spacing: -0.2px;
}

.form-control.is-valid+.input-group-addon,
.was-validated .form-control:valid+.input-group-addon {
    border: 1px solid #28a745;
    border-left: 0;
}

.form-control.is-invalid+.input-group-addon,
.was-validated .form-control:invalid+.input-group-addon {
    border: 2px solid #DC393A;
    border-left: 0;
    line-height: 44px;
}

.input-group>.form-control.is-invalid,
.was-validated .input-group>.form-control:invalid {
    border-right: 0;
}

.toggle-password path {
    stroke: #738399;
}

.toggle-password.active path {
    stroke: #19202E;
}

.toggle-password-confirm path {
    stroke: #738399;
}

.toggle-password-confirm.active path {
    stroke: #19202E;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

