/****** MODAL ******/

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px 40px;
    border-bottom: 1px solid #DDE3ED;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.modal-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
    letter-spacing: -0.5px;
    color: #11151F;
}

.close {
    float: right;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.2px;
    color: #0068B3;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: #424E66;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    /*padding: 40px;*/
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 32px 40px;
    border-top: 1px solid transparent;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
    background: #F5F7FA;
}

.modal-success {
    text-align: center;
}

.modal-success .modal-dialog .modal-content {
    background-repeat: repeat;
    border-radius: 12px;
}

.modal-success h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #11151F;
    margin-bottom: 30px;
}

.modal-success p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #738399;
    margin-bottom: 30px
}

.modal-success img {
    margin-bottom: 40px;
}

/*#matricula-realizada h3 {*/
/*    margin-bottom: 8px*/
/*}*/

/*#matricula-realizada p {*/
/*    margin-bottom: 32px*/
/*}*/

/*#matricula-realizada button {*/
/*    margin-top: 24px*/
/*}*/

.modal-header .close span b {
    font-size: 30px;
    font-weight: 400;
    vertical-align: sub;
    margin-left: 10px;
}

.modal button.close span {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.2px;
    color: #0068B3;
}


@media screen and (max-width: 575px) {
    .modal-dialog {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: 0;
        border-radius: 0;
    }
}
