
/****** CHECKBOX ******/

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
    text-indent: 2px;
}

.custom-control-label::before {
    position: absolute;
    top: 0.19rem;
    left: -1.5rem;
    display: block;
    width: 18px;
    height: 18px;
    pointer-events: none;
    content: "";
    border: 1px solid #B8C6D9;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    margin-right: 8px;
}

.custom-control-label::after {
    position: absolute;
    top: .1rem;
    left: -1.6rem;
    display: block;
    width: 22px;
    height: 22px;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L4 8L1 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-label::before {
    border-radius: 4px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #FFFFFF;
    border-color: #0068b3;
    background-color: #0068b3;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #0068B3
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #DDE3ED;
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #DDE3ED;
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #FFFFFF;
    border-color: #DDE3ED;
    box-shadow: none;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #FFFFFF;
    background-color: #004A80;
    border-color: #004A80;
}
